import { t } from '@lingui/macro'
import { Spinner } from 'components'
import { Layout, LoginRedirect, useUpdateAbility } from 'containers'
import { NextPage } from 'next'
import { destroyCookie, parseCookies } from 'nookies'
import React, { useEffect } from 'react'
import { Router } from 'utils/router'
import { useIsLoggedIn } from 'utils/withAuth'

interface LoginPageProps {
  redirectURL?: string
}

const LoginPage: NextPage<LoginPageProps> = ({ redirectURL }) => {
  const isLoggedIn = useIsLoggedIn()
  const updateAbility = useUpdateAbility()

  useEffect(() => {
    const handleLogin = async () => {
      await updateAbility()
      Router.replaceRoute(redirectURL || '/dashboard')
    }

    if (isLoggedIn) {
      handleLogin()
    }
  }, [isLoggedIn, updateAbility, redirectURL])

  return (
    <Layout title={t`login.title`} background="grey">
      <div className="flex py-100 items-center justify-center">
        <div className="max-w-400">
          {isLoggedIn ? <Spinner /> : <LoginRedirect kind="login" />}
        </div>
      </div>
    </Layout>
  )
}

LoginPage.getInitialProps = async (ctx) => {
  const cookies = parseCookies(ctx)
  const cookieId = ctx.query.state
  const redirectURL = cookies[cookieId as string]

  destroyCookie(ctx, cookieId as string, { path: '/login' })

  return { redirectURL }
}

export default LoginPage
